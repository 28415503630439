import ability from './ability'

export const canNavigate = to => {
  if (Array.isArray(to.meta.resource)) {
    return to.matched.some(route => to.meta.resource.some(resource => ability.can(route.meta.action || 'read', resource)))
  }

  return to.matched.some(route => ability.can(route.meta.action || 'read', to.meta.resource))
}

export const _ = undefined
