export default [

  {
    path: '/futures',
    name: 'fut-home',
    component: () => import('@/views/futures/Home.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/ordem-aberta',
    name: 'fut-ordem-aberta',
    component: () => import('@/views/futures/ordem-aberta/OrdemAberta.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/posicao',
    name: 'fut-posicao',
    component: () => import('@/views/futures/posicao/Posicao.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/transferencia',
    name: 'fut-transfer',
    component: () => import('@/views/futures/transferencia/Transfer.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/relatorio/fechamento',
    name: 'fut-relatorio-fechamento',
    component: () => import('@/views/futures/relatorio/fechamento/Fechamento.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/relatorio/sinal',
    name: 'fut-relatorio-sinal',
    component: () => import('@/views/futures/relatorio/sinal/Sinal.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/meu-agente',
    name: 'fut-meu-agente',
    component: () => import('@/views/futures/meu-agente/MeuAgente.vue'),
    meta: {
      resource: 'assinante-futures',
    },
  },
  {
    path: '/futures/meu-agente/:id',
    name: 'fut-meu-agente-edit',
    component: () => import('@/views/futures/meu-agente/MeuAgenteEdit.vue'),
    meta: {
      resource: 'assinante-futures',
      pageTitle: 'Configurar',
      breadcrumb: [
        {
          text: 'Meus agentes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/futures/agente/operacao-aberta',
    name: 'fut-agente-operacao-aberta',
    component: () => import('@/views/futures/agente/operacaoAberta/OperacaoAberta.vue'),
    meta: {
      resource: ['agente-futures', 'agente-futures-robo'],
    },
  },
  {
    path: '/futures/agente/nova-operacao',
    name: 'fut-agente-nova-operacao',
    component: () => import('@/views/futures/agente/novaOperacao/NovaOperacao.vue'),
    meta: {
      resource: 'agente-futures',
    },
  },
  {
    path: '/futures/agente/cadastrar-usuario',
    name: 'fut-agente-cadastrar-usuario',
    component: () => import('@/views/futures/agente/cadastroUsuario/CadastroUsuario.vue'),
    meta: {
      resource: 'agente-futures',
    },
  },
  {
    path: '/futures/agente/assinante',
    name: 'fut-agente-assinante',
    component: () => import('@/views/futures/agente/assinante/Assinante.vue'),
    meta: {
      resource: ['agente-futures', 'agente-futures-robo'],
    },
  },
  {
    path: '/futures/agente/assinatura/cobranca',
    name: 'fut-agente-assinatura-cobranca',
    component: () => import('@/views/futures/agente/assinaturaCobranca/AssinaturaCobranca.vue'),
    meta: {
      resource: ['agente-futures', 'agente-futures-robo'],
    },
  },
  {
    path: '/futures/agente/assinante-desempenho',
    name: 'fut-agente-assinante-desempenho',
    component: () => import('@/views/futures/agente/assinanteDesempenho/AssinanteDesempenho.vue'),
    meta: {
      resource: ['agente-futures', 'agente-futures-robo'],
    },
  },
  {
    path: '/futures/afiliado/sessao-usuario',
    name: 'fut-afiliado-sessao-usuario',
    component: () => import('@/views/futures/afiliado/sessaoUsuario/SessaoUsuario.vue'),
    meta: {
      resource: 'afiliado',
    },
  },

  // monitor
  {
    path: '/futures/monitor/aprovar-operacao',
    name: 'fut-monitor-aprovar-operacao',
    component: () => import('@/views/futures/monitor/aprovar-operacao/AprovarOperacaoMonitor.vue'),
    meta: {
      resource: 'agente-futures-robo',
      pageTitle: 'Aprovador',
      breadcrumb: [
        {
          text: 'Aprovar operação',
          active: true,
        },
      ],
    },
  },
  {
    path: '/futures/monitor/operador-estrategia/instancia',
    name: 'fut-monitor-operador-estrategia-instancias',
    component: () => import('@/views/futures/monitor/operador/OperadorEstrategiaInstanciaMonitor.vue'),
    meta: {
      resource: 'agente-futures-robo',
      pageTitle: 'Monitor',
      breadcrumb: [
        {
          text: 'Operador',
          active: false,
        },
        {
          text: 'Estratégia',
          active: true,
        },
      ],
    },
  },
  {
    path: '/futures/monitor/operador-estrategia/instancia/:id/operacao/:idOp',
    name: 'fut-monitor-operador-estrategia-instancia-view',
    component: () => import('@/views/futures/monitor/operador/OperadorEstrategiaInstanciaMonitorView.vue'),
    meta: {
      resource: 'agente-futures-robo',
      pageTitle: 'Monitor',
      breadcrumb: [
        {
          text: 'Operador',
          active: false,
        },
        {
          text: 'Instância',
          active: false,
        },
        {
          text: 'Operação',
          active: true,
        },
      ],
    },
  },

  {
    path: '/publico/futures/performance',
    name: 'publico-futures-perfomance',
    component: () => import('@/views/publico/futures/Performance.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

]
