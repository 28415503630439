import axios from '@axios'

export default {
  namespaced: true,
  state: {
    empresa: null,
  },
  getters: {},

  mutations: {
    SET_EMPRESA(state, data) {
      state.empresa = data
    },
  },

  actions: {
    getEmpresa(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/publico/empresa', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },

}
