import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(error.message)
    }

    // const { config, response: { status } } = error
    const { response } = error
    // if (status === 401) {
    if (response && response.status === 403) {
      localStorage.removeItem('userData')
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }
    return Promise.reject(error.response)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
