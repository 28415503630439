<template>
  <div
    v-if="translationsLoaded"
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { localize } from 'vee-validate'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { getUserData } from '@/auth/utils'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },

  data() {
    return {
      translationsLoaded: false,
    }
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    const { skin, empresa } = useAppConfig()

    store.dispatch('empresa/getEmpresa', { dominio: window.location.host })
      .then(response => {
        empresa.value = response.data
        document.title = response.data.nome

        const userData = getUserData()

        if (userData && userData.idioma) {
          this.$i18n.locale = userData.idioma
        } else if (response.data.idioma) {
          this.$i18n.locale = response.data.idioma
        }
        localize(this.$i18n.locale)
        this.translationsLoaded = true

        // adicionando manisfest
        const link = document.createElement('link')
        link.href = `${process.env.VUE_APP_STATIC}empresa/${store.state.empresa.empresa.id}/manifest.json`
        link.rel = 'manifest'
        document.getElementsByTagName('head')[0].appendChild(link)

        // adicionando caminho dos links do meta
        const empresaRepo = `${process.env.VUE_APP_STATIC}empresa/${store.state.empresa.empresa.id}/`
        const links = document.querySelectorAll('head link[rel="icon"],head link[rel="apple-touch-icon"]')
        links.forEach(e => {
          const caminho = e.href.split('/')
          e.href = empresaRepo + caminho[caminho.length - 1]
        })

        // atribuindo cor padrao da empresa
        if (empresa.value.modoTela === 'dark' && localStorage.getItem('vuexy-skin') == null) {
          skin.value = 'dark'
        }
      })

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
  },

  setup() {
    const { skinClasses } = useAppConfig()

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
