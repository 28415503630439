export default {
  // Endpoints
  loginEndpoint: '/auth',
  registerEndpoint: '/auth/registrar',
  refreshEndpoint: '/jwt/refresh-token', // sem uso
  logoutEndpoint: '/jwt/logout', // sem uso

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken', // sem uso

  storageImpersonateKeyName: 'cb-impersonate',

}
