import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import binanceSpot from './spot/binanceModule'
import binanceFut from './fut/binanceModule'
import notificacao from './sistema/notificacao'
import alerta from './sistema/alerta'
import empresa from './sistema/empresa'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    binanceSpot,
    binanceFut,
    notificacao,
    alerta,
    empresa,
  },
  strict: process.env.DEV,
})
