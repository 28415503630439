export default [
  // {
  //   path: '/spot/sinal-ativo',
  //   name: 'spot-sinal-ativo',
  //   component: () => import('@/views/spot/sinal-ativo/SinalAtivo.vue'),
  // },
  {
    path: '/spot',
    name: 'home',
    component: () => import('@/views/spot/Home.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  // {
  //   path: '/spot/faq',
  //   name: 'spot-faq',
  //   component: () => import('@/views/faq/Faq.vue'),
  //   meta: {
  //     pageTitle: 'Faq',
  //     resource: 'assinante',
  //     breadcrumb: [
  //       {
  //         text: 'Contas',
  //       },
  //     ],
  //   },
  // },
  {
    path: '/spot/faq/:slugCategory',
    name: 'spot-faq-category',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'Faq',
      resource: 'assinante',
      breadcrumb: [
        {
          text: 'Contas',
        },
        {
          text: 'Faq',
          to: '/spot/faq',
        },
        {
          text: 'Categoria',
          active: true,
        },
      ],
      navActiveLink: 'spot-faq',
    },
  },
  {
    path: '/spot/faq/:slugCategory/:slugAnswer',
    name: 'spot-faq-category-Answer',
    component: () => import('@/views/faq/FaqAnswer.vue'),
    meta: {
      pageTitle: 'Faq',
      resource: 'assinante',
      breadcrumb: [
        {
          text: 'Contas',
        },
        {
          text: 'Faq',
          to: '/spot/faq',
        },
        {
          text: 'Pergunta',
          active: true,
        },
      ],
      navActiveLink: 'spot-faq-category',
    },
  },
  {
    path: '/spot/compra-aberta',
    name: 'spot-compra-aberta',
    component: () => import('@/views/spot/compra-aberta/CompraAberta.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/venda-aberta',
    name: 'spot-venda-aberta',
    component: () => import('@/views/spot/venda-aberta/VendaAberta.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/cambio',
    name: 'spot-cambio',
    component: () => import('@/views/spot/cambio/Cambio.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/relatorio/fechamento',
    name: 'spot-relatorio-fechamento',
    component: () => import('@/views/spot/relatorio/fechamento/Fechamento.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/relatorio/sinal',
    name: 'spot-relatorio-sinal',
    component: () => import('@/views/spot/relatorio/sinal/Sinal.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/meu-agente',
    name: 'spot-meu-agente',
    component: () => import('@/views/spot/meu-agente/MeuAgente.vue'),
    meta: {
      resource: 'assinante',
    },
  },
  {
    path: '/spot/meu-agente/:id',
    name: 'spot-meu-agente-edit',
    component: () => import('@/views/spot/meu-agente/MeuAgenteEdit.vue'),
    meta: {
      resource: 'assinante',
      pageTitle: 'Configurar',
      breadcrumb: [
        {
          text: 'Meus agentes',
          active: true,
        },
      ],
    },
  },

  {
    path: '/spot/monitor/operador-estrategia/instancia',
    name: 'spot-monitor-operador-estrategia-instancias',
    component: () => import('@/views/spot/monitor/operador/OperadorEstrategiaInstanciaMonitor.vue'),
    meta: {
      resource: 'agente-robo',
      pageTitle: 'Monitor',
      breadcrumb: [
        {
          text: 'Operador',
          active: false,
        },
        {
          text: 'Estratégia',
          active: true,
        },
      ],
    },
  },
  {
    path: '/spot/monitor/operador-estrategia/instancia/:id/operacao/:idOp',
    name: 'spot-monitor-operador-estrategia-instancia-view',
    component: () => import('@/views/spot/monitor/operador/OperadorEstrategiaInstanciaMonitorView.vue'),
    meta: {
      resource: 'agente-robo',
      pageTitle: 'Monitor',
      breadcrumb: [
        {
          text: 'Operador',
          active: false,
        },
        {
          text: 'Instância',
          active: false,
        },
        {
          text: 'Operação',
          active: true,
        },
      ],
    },
  },

  {
    path: '/spot/agente/operacao-aberta',
    name: 'spot-agente-operacao-aberta',
    component: () => import('@/views/spot/agente/operacaoAberta/OperacaoAberta.vue'),
    meta: {
      resource: ['agente', 'agente-robo'],
    },
  },
  {
    path: '/spot/agente/nova-operacao',
    name: 'spot-agente-nova-operacao',
    component: () => import('@/views/spot/agente/novaOperacao/NovaOperacao.vue'),
    meta: {
      resource: 'agente',
    },
  },
  {
    path: '/spot/agente/cadastrar-usuario',
    name: 'spot-agente-cadastrar-usuario',
    component: () => import('@/views/spot/agente/cadastroUsuario/CadastroUsuario.vue'),
    meta: {
      resource: 'agente',
    },
  },
  {
    path: '/spot/agente/configuracao',
    name: 'spot-agente-configuracao',
    component: () => import('@/views/spot/agente/configuracao/Configuracao.vue'),
    meta: {
      resource: 'agente',
    },
  },
]
