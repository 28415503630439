import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    tickers: {},
    chartData: [],
  },
  getters: {},
  mutations: {
    /* eslint no-param-reassign: "error" */
    /* eslint no-nested-ternary: 0 */

    UPDATE_TICKER: (state, payload) => {
      const tick = state.tickers[payload.symbol]
      payload.pchg = tick ? (payload.price > tick.price ? 1 : -1) : 1
      Vue.set(state.tickers, payload.symbol, payload)
    },
  },
  actions: {
    updateTicket({ commit }, payload) {
      commit('UPDATE_TICKER', payload)
    },

    getPriceBySymbolRest(symbol) {
      return new Promise((resolve, reject) => {
        axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
