import axios from '@axios'

export default {
  namespaced: true,
  state: {
    sino: null,
  },
  getters: {},
  mutations: {
    SET_SINO(state, data) {
      state.sino = data
    },
  },

  actions: {

    setLidos() {
      return new Promise((resolve, reject) => {
        axios.post('notificacao/lidos')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getNotificacoesSino({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('notificacao/sino')
          .then(response => {
            commit('SET_SINO', response.data)
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getNotificacoes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('notificacao', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
