<template lang="html">
  <vue-apex-charts
    ref="chart"
    type="donut"
    height="260"
    :options="resumoSinaisChartOptions"
    :series="series"
  />
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue-apexcharts'

  export default {
    name: "GraficoSinal",

    components: {
        VueApexCharts,
    },

    props: {
      resumoSinal: {
        default: []
      },
      series: {
        default: []
      },
    },

     computed: {

        windowWidth() {
            return this.$store.state.app.windowWidth
        },

        resumoSinaisChartOptions() {
        const _self = this
        const config = {
          noData: {
            text: 'Loading...',
          },
          colors: ['#5b3cc4', 'rgb(64, 138, 81)', 'rgb(242, 19, 93)'],
          labels: [_self.$i18n.t('textAtivos'),_self.$i18n.t('textGanhos'), _self.$i18n.t('textPerdas')],
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          fill: {
            type: 'gradient',
          },
          plotOptions: {
            pie: {
              startAngle: -120,
              endAngle: 120,
              offsetY: 10,
              donut: {
                size: '85%',
                labels: {
                  show: true,
                  name: {
                    fontSize: '1rem',
                    fontWeight: '900',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    show: true,
                    fontSize: '1rem',
                    fontWeight: '900',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      return `${val}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    fontWeight: '900',
                    label: _self.resumoSinal.total > 0 ? _self.$i18n.t('textTotal') : '',
                    formatter() {
                        return _self.resumoSinal.total > 0 ? _self.resumoSinal.total : _self.$i18n.t('textSemOperacao')
                    },
                  },
                },
              },
  
            },
          },
          grid: {
            padding: {
              bottom: -40,
            },
          },
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            showForZeroSeries: true,
            formatter(seriesName, opts) {
              let qtde = 0
              if (seriesName == _self.$i18n.t('textAtivos')) {
                qtde = _self.resumoSinal.totalAtivo
              } else if (seriesName == _self.$i18n.t('textGanhos')) {
                qtde = _self.resumoSinal.totalGanho
              } else if (seriesName == _self.$i18n.t('textPerdas')) {
                qtde = _self.resumoSinal.totalPerda
              }
              return `
                  <div class='mr-1 mt-1' style="display: flex; flex-direction: column; align-items: center;">
                    <span style="position: relative; font-size: 16px;">${seriesName}</span>
                    <span style="font-size: 16px;">${qtde}</span>
                  </div>
                `;
              },
          },
        }
        return config
      },
     }
  }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>