import axios from '@axios'

export default {
  namespaced: true,
  state: {
    alertas: [],
  },
  getters: {},
  mutations: {
    SET_ALERTA(state, data) {
      state.alertas = data
    },
  },
  actions: {
    getAlerta({ commit }, contexto) {
      return new Promise((resolve, reject) => {
        axios.get(`alerta/${contexto}`)
          .then(response => {
            commit('SET_ALERTA', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
